import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  MatFormField,
  MatFormFieldAppearance,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { User } from '@models/users/user.model';
import { AuthDialog } from '@modules/auth/dialogs/auth/auth.dialog';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-personal-data',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    TranslateModule,
    MatInput,
    TelInputComponent,
    UserAvatarComponent,
    MatCheckbox,
  ],
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PersonalDataComponent {
  selectedOption: string = 'email';
  @Input() form?: FormGroup;
  @Input() loggedUser?: User;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() isBookASlot = false;
  @Input() showTitle: boolean = true;
  @Input({ transform: booleanAttribute }) showLoggedUserData: boolean = false;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
  ) {}

  selectOption(option: string): void {
    this.selectedOption = option;
  }

  login(isLogin: boolean) {
    const authDialog = this.dialog.open(AuthDialog, {
      viewContainerRef: this.viewContainerRef,
      width: '100%',
      height: 'auto',
      maxWidth: '600px',
      panelClass: ['normal-dialog'],
      data: { isLogin: isLogin, isTicketing: true, showBackButton: false },
    });

    authDialog.afterClosed().subscribe((res) => {
      if (res && res.success) {
        this.authService.refreshTheLoggedUserData();
      }
    });
  }
}
